.btn {
  width: 100%;
  margin-bottom: 16px;
}

.infoBlock {
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .btn {
    margin-right: 16px;
    width: auto;
  }
}
